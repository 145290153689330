import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { createAsyncRequest } from '../utils';

export const getChannelUsers = createAsyncThunk('USER_CHANNEL_GET',
  createAsyncRequest((channelId: number) => {
    return api.get('channels/users', { params: { channelId } });
  })
);
