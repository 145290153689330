import React, { useState } from 'react';
import { useLocation, useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';

import qs from 'qs';
import { authSelector } from '../features/auth/selectors';
import { resetPassword as resetPasswordRequest } from '../features/auth/actions';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonButton,
  IonItem,
  IonLabel,
  IonInput
} from "@ionic/react";
import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';

import { AppDispatch } from '../store/store';

const ResetPassword: React.FC = () => {
  const { errorMessage } = useSelector(authSelector);
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const passwordResetCode = qs.parse(location.search, { ignoreQueryPrefix: true }).code;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  console.log('reset password');

  const resetPassword = () => {
    return dispatch(resetPasswordRequest({ passwordResetCode, password, confirmPassword })).then(() => {
      history.push('/login');
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Reset Password
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ErrorMessage errorMessage={errorMessage} />
        <LoadingIndicator />
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <IonList>
            <IonItem>
              <IonLabel>Password</IonLabel>
              <IonInput name="email" type="password" value={password} onIonChange={(e) => setPassword(e.detail.value!)} />
            </IonItem>
            <IonItem>
              <IonLabel>Confirm Password</IonLabel>
              <IonInput name="email" type="password" value={confirmPassword} onIonChange={(e) => setConfirmPassword(e.detail.value!)} />
            </IonItem>
          </IonList>
          <IonButton expand="block" onClick={resetPassword}>Reset Password</IonButton>
        </form>
      </IonContent>
    </IonPage>
  )
};

export default ResetPassword;
