import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { createAsyncRequest } from '../utils';

export const getChannelClips = createAsyncThunk('CLIPS_CHANNEL_GET',
  createAsyncRequest((channelId: number) => {
    return api.get('channels/clips', {
      params: {
        channelId
      }
    });
  })
);

export const deleteClip = createAsyncThunk('CLIP_DELETE',
  createAsyncRequest((clipId: number) => {
    return api.delete('clips', {
      params: {
        clipId
      }
    });
  })
);

export const confirmClipUpload = createAsyncThunk('CLIP_CONFIRM_UPLOAD',
  createAsyncRequest((clipId: number) => {
    return api.post('clips/confirmUpload', {}, {
      params: {
        clipId
      }
    });
  })
);
