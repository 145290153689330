import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authSelector } from '../features/auth/selectors';
import { AUTH_YES } from '../features/auth/consts';
import { clearErrors } from '../features/auth/actions';

const UnauthenticatedRoute = ({ component: Component, ...rest }: any) => {
  const { authStatus } = useSelector(authSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrors(null));
  }, [dispatch]);

  return (
    <Route {...rest} render={(props) => (
      authStatus === AUTH_YES
        ? <Redirect to='/home' />
        : <Component {...props} />
    )} />
  )
}

export default UnauthenticatedRoute;