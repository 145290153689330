import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import api from '../../services/api';
import { createAsyncRequest } from '../utils';
import { Group } from '../../models/Group';

export const createGroup = createAsyncThunk('GROUP_CREATE',
  async (args: { group?: Group, name: string, description: string, visibility: string, organizationId?: number }, thunkApi) => {
    if (!args.name) {
      throw new Error('Please enter a group name.');
    }
    const { data } = await api.post('groups/create', args);
    if (!data.error) {
      thunkApi.dispatch(setGroupEditModalOpen(false));
    }
    return data.group;
  }
);

export const getGroups = createAsyncThunk('GROUPS_GET',
  createAsyncRequest((organizationId: number) => {
    return api.get('groups', {
      params: {
        organizationId
      }
    });
  })
);

export const updateGroup = createAsyncThunk('GROUP_UPDATE',
  async (args: { group?: Group, name: string, description: string, visibility: string, organizationId?: number }, thunkApi) => {
    if (!args.group) {
      throw new Error('Missing group id');
    }
    if (!args.name) {
      throw new Error('Please enter a prayer request name.');
    }
    const { data } = await api.post('groups/update', {
      groupId: args.group.id,
      name: args.name,
      description: args.description,
      visibility: args.visibility,
    });
    console.log('group updated', data);
    if (!data.error) {
      thunkApi.dispatch(setGroupEditModalOpen(false));
    }
    return data.group;
  }
);

export const deleteGroup = createAsyncThunk('GROUP_DELETE',
  createAsyncRequest((groupId: number) => {
    return api.delete('groups/delete', {
      params: {
        groupId
      }
    });
  })
);

export const setGroupEditModalOpen = createAction('GROUP_EDIT_MODAL_OPEN_SET', isOpen => {
  return { payload: { isOpen } };
});
