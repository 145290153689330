import { createSlice } from '@reduxjs/toolkit'

import { uploadAudio } from './actions';

const initialState = {
  error: {},
  loading: false
};

const reducer = createSlice({
  name: 's3',
  initialState,
  reducers: {},
  extraReducers: {
    [uploadAudio.pending] : state => {
      state.loading = true;
    },
    [uploadAudio.fulfilled] : state => {
      state.loading = false;
      state.error = {};
    },
    [uploadAudio.rejected] : (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
