import { createAsyncThunk } from '@reduxjs/toolkit';

import { createAsyncRequest } from '../utils';
import api, { setAuthHeader } from '../../services/api';
import { createAction } from '@reduxjs/toolkit';
import { AUTH_IN_PROGRESS } from './consts';
import { RegistrationInfo } from '../../models/RegistrationInfo';

export const setAuthStatus = createAction('AUTH_STATUS_SET', status => {
  return {
    payload: { status }
  };
});

export const login = createAsyncThunk('AUTH_LOGIN',
  async (args: { email: string, password: string }, thunkApi) => {
    const res = await api.post('login', args);
    localStorage.setItem('authToken', JSON.stringify(res.data.token));
    setAuthHeader();
    thunkApi.dispatch(getCurrentUser(null));
    return res.data;
  }
);

export const register = createAsyncThunk('AUTH_REGISTER',
  async (args: { email: string, password: string, confirmPassword: string, firstName: string, lastName: string }) => {
    if (args.password !== args.confirmPassword) {
      return Promise.reject(new Error('Passwords do not match'));
    }
    const res = await api.post('register', args);
    setAuthStatus(AUTH_IN_PROGRESS);
    return res.data;
  }
);

export const validateRegistrationInformation = createAsyncThunk('AUTH_VALIDATE',
  async (registrationInfo: RegistrationInfo) => {
    const { password, confirmPassword } = registrationInfo;
    if (password !== confirmPassword) {
      throw new Error('Passwords do not match');
    }

    const { data } = await api.get(`register/checkEmail`,
      {
        params: {
          email: registrationInfo.email
        }
      }
    );
    if (data.userExists) {
      throw new Error('User with this email already exists');
    }
    return registrationInfo;
  });

export const verify = createAsyncThunk('AUTH_VERIFY',
  createAsyncRequest((verificationCode: string) => {
    return api.post('verify', { verificationCode });
  })
);

export const sendVerification = createAsyncThunk('AUTH_SEND_VERIFICATION',
  createAsyncRequest((email: string) => {
    return api.post('sendVerification', {}, { params: { email } });
  })
);

export const getCurrentUser = createAsyncThunk('AUTH_USER_ME_GET',
  createAsyncRequest(() => {
    return api.get('users/me');
  })
);

export const forgotPassword = createAsyncThunk('AUTH_FORGOT_PASSWORD',
  createAsyncRequest((email: string) => {
    return api.post('forgotPassword', {}, { params: { email } });
  })
);

export const resetPassword = createAsyncThunk('AUTH_RESET_PASSWORD',
  createAsyncRequest((args: { passwordResetCode: string, password: string, confirmPassword: string }) => {
    if (args.password !== args.confirmPassword) {
      throw new Error('Passwords do not match');
    }
    return api.post('resetPassword', args);
  })
);

export const clearErrors = createAction('AUTH_CLEAR_ERRORS', status => {
  return {
    payload: { }
  };
});
