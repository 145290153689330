import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonList,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  IonPopover,
  IonLabel,
} from '@ionic/react';
import { ellipsisVertical, close } from 'ionicons/icons';

const SecondaryMenu = ({ items }: { items: any[]}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState();

  return (
    <>
      <IonButtons slot="end">
        <IonButton onClick={(e: any) => {
          e.persist();
          setShowPopover(true);
          setPopoverEvent(e);
        }}>
          <IonIcon icon={ellipsisVertical} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonPopover
        mode="ios"
        cssClass='my-custom-class'
        event={popoverEvent}
        isOpen={showPopover}
        onDidDismiss={() => {
          setShowPopover(false);
          setPopoverEvent(undefined);
        }}
      >
        <IonList lines="none" class="menu-list">
          {items.map(item => (
            <IonItem key={item.text} onClick={item.handler} color={item.color}>
              <IonIcon icon={item.icon} slot="start" />
              {item.text}
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
    </>
  );
}

export default SecondaryMenu;
