import { createSlice } from '@reduxjs/toolkit';

import { createChannel, getChannels, updateChannel, setChannelEditModalOpen } from './actions';
import { mapToObjectWithIdKey } from '../utils';

const initialState = {
  error: {},
  loading: false,
  channels: {},
  modalOpen: false
};

const reducer = createSlice({
  name: 'channels',
  initialState,
  reducers: {},
  extraReducers: {
    [setChannelEditModalOpen]: (state, action) => {
      state.modalOpen = action.payload.isOpen;
      state.error = {};
    },
    [createChannel.pending]: state => {
      state.loading = true;
    },
    [createChannel.fulfilled]: (state, action) => {
      state.loading = false;
      state.channels[action.payload.id] = action.payload;
    },
    [createChannel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getChannels.pending]: state => {
      state.loading = true;
    },
    [getChannels.fulfilled]: (state, action) => {
      state.loading = false;
      state.channels = mapToObjectWithIdKey(action.payload.channels);
    },
    [getChannels.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateChannel.pending]: state => {
      state.loading = true;
    },
    [updateChannel.fulfilled]: (state, action) => {
      state.loading = false;
      state.channels[action.payload.id] = action.payload;
    },
    [updateChannel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
