import { createSlice } from '@reduxjs/toolkit'

import { getChannelUsers } from './actions';
import * as R from 'ramda';
import { mapToObjectWithIdKey } from '../utils';

const initialState = {
  error: {},
  loading: false,
  users: {}
};

const reducer = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [getChannelUsers.pending] : state => {
      state.loading = true;
    },
    [getChannelUsers.fulfilled] : (state, action) => {
      state.loading = false;
      state.error = {};
      state.users = R.mergeDeepRight(state.users, mapToObjectWithIdKey(action.payload.users));
    },
    [getChannelUsers.rejected] : (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
