import { createSlice } from '@reduxjs/toolkit';

import { getGroupAccessRequests, createGroupAccessRequest, grantGroupAccess, denyGroupAccess } from './actions';

const initialState = {
  error: {},
  loading: false,
  requests: []
};

const reducer = createSlice({
  name: 'groupAccessRequests',
  initialState,
  reducers: {},
  extraReducers: {
    [getGroupAccessRequests.pending]: state => {
      state.loading = true;
    },
    [getGroupAccessRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.groupAccessRequests;
    },
    [getGroupAccessRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createGroupAccessRequest.pending]: state => {
      state.loading = true;
    },
    [createGroupAccessRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests.push(action.payload);
    },
    [createGroupAccessRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [grantGroupAccess.pending]: state => {
      state.loading = true;
    },
    [grantGroupAccess.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.groupAccessRequests;
    },
    [grantGroupAccess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [denyGroupAccess.pending]: state => {
      state.loading = true;
    },
    [denyGroupAccess.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.groupAccessRequests;
    },
    [denyGroupAccess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
