import { createSlice } from '@reduxjs/toolkit'

import { getGroups, createGroup, updateGroup, setGroupEditModalOpen } from './actions';
import { mapToObjectWithIdKey } from '../utils';

const initialState = {
  error: {},
  loading: false,
  groups: [],
  modalOpen: false,
};

const reducer = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: {
    [setGroupEditModalOpen]: (state, action) => {
      state.modalOpen = action.payload.isOpen;
      state.error = {};
    },
    [getGroups.pending]: state => {
      state.loading = true;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.groups = mapToObjectWithIdKey(action.payload.groups);
    },
    [getGroups.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createGroup.pending]: state => {
      state.loading = true;
    },
    [createGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.groups[action.payload.id] = action.payload;
    },
    [createGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateGroup.pending]: state => {
      state.loading = true;
    },
    [updateGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.groups[action.payload.id] = action.payload;
    },
    [updateGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
