import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../store/store';
import {
  IonPage,
  IonContent,
  IonButton,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonButtons,
  IonIcon,
  IonBadge,
  IonItemGroup,
  IonItemDivider,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonCheckbox,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
} from '@ionic/react';
import LoadingIndicator from '../components/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../features/groups/actions';
import { authSelector } from '../features/auth/selectors';
import { groupSelector, groupAccessRequestCountSelector } from '../features/groupAccessRequests/selectors';
import { getGroupAccessRequests, grantGroupAccess, denyGroupAccess } from '../features/groupAccessRequests/actions';
import Menu from '../components/Menu';
import './Admin.css';
import userEvent from '@testing-library/user-event';

const Home: React.FC = () => {
  const { user } = useSelector(authSelector);
  const { groupsWithRequests } = useSelector(groupSelector);
  const { groupAccessRequestCount } = useSelector(groupAccessRequestCountSelector)
  const dispatch: AppDispatch = useDispatch();
  const [selected, setSelected] = useState([] as string[]);
  const [toastMessage, setToastMessage] = useState('approved');
  const [showToast, setShowToast] = useState(false);
  const orgId = 1;
  
  useEffect(() => {
    // Currently org id 1 is on the only org, but this will have to be pulled from state when we have multiple orgs
    dispatch(getGroups(orgId)).then(() => {
      dispatch(getGroupAccessRequests(orgId));
    });
  }, [dispatch, orgId]);
  
  const setChecked = (requestKey, checked) => {
    setSelected(checked ? selected.concat([requestKey]) : selected.filter(key => key !== requestKey));
  };

  const getSelectedRequests = () => {
    return selected.map(requestKey => {
      const [groupId, userId] = requestKey.split('_');
      console.log('groupId', groupId, 'userId', userId);
      const group = groupsWithRequests.find(g => g.id == groupId);
      const request = group.requests.find(r => r.userId == userId);
      return {
        groupId,
        userId,
        groupName: group.name,
        userEmail: request.User.email,
      };
    });
  };

  const approve = () => {
    dispatch(grantGroupAccess(getSelectedRequests())).then(() => {
      setToastMessage('approved');
      setShowToast(true);
      setSelected([]);
    });
  };

  const deny = () => {
    dispatch(denyGroupAccess(getSelectedRequests())).then(() => {
      setToastMessage('denied');
      setShowToast(true);
      setSelected([]);
    });
  };

  return (
    <div>
      <Menu contentId="admin" />
      <IonPage id="admin">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              {!!groupAccessRequestCount && (
                <IonBadge mode="ios" slot="end" class="menu-button-badge">{groupAccessRequestCount}</IonBadge>
              )}
            </IonButtons>
            <IonTitle class="page-title">Group Access Requests</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent class="admin-page-content">
          <LoadingIndicator />
          <IonToast
            isOpen={showToast}
            color="dark"
            position="middle"
            onDidDismiss={() => setShowToast(false)}
            message={`The selected users have been ${toastMessage}`}
            duration={2000}
          />
          {!!groupsWithRequests.length ? groupsWithRequests.map((group: any) => (
            <IonItemGroup key={group.id}>
              <IonItemDivider color="light">
                <IonLabel class="group-access-request-list-label">{group.name}</IonLabel>
              </IonItemDivider>
              <IonList lines="full" mode="ios">
                {group.requests.map((request: any) => {
                  const requestKey = `${request.groupId}_${request.userId}`;
                  return (
                    <IonItem key={requestKey}>
                      <IonLabel>{request.User.firstName} {request.User.lastName}</IonLabel>
                      <IonLabel>{request.User.email}</IonLabel>
                      <IonCheckbox
                        slot="end"
                        checked={!!selected.includes(requestKey)}
                        onIonChange={e => setChecked(requestKey, e.detail.checked)}
                      />
                    </IonItem>
                  );
                })}
              </IonList>
            </IonItemGroup>
          )) : (
            <IonText class="empty-page-message" color="dark">
              <p>There are currently no group access requests</p>
            </IonText>
          )}
        </IonContent>
        <IonFooter>
          <IonToolbar>
            {user.canManageGroupAccessRequests && !!selected.length && (
              <IonGrid>
                <IonRow>
                  <IonCol size="8">
                    <IonButton expand="block" color="primary" onClick={approve}>Approve Access</IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton expand="block" color="danger" onClick={deny}>Deny Access</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
            {user.canManageGroupAccessRequests && !selected.length && (
              <IonText class="footer-action-instructions"><p>Click users above to select them</p></IonText>
            )}
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </div>
  );
};

export default Home;
