import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AUTH_NOT_VERIFIED, AUTH_IN_PROGRESS } from '../features/auth/consts';
import { Redirect } from "react-router";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonRouterLink,
} from "@ionic/react";

import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import { validateRegistrationInformation } from '../features/auth/actions';
import { authSelector } from '../features/auth/selectors';

const Registration: React.FC = () => {
  const { authStatus, errorMessage, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [password, setPassword] = useState(user.password);
  const [confirmPassword, setConfirmPassword] = useState(user.confirmPassword);

  const registerNext = () => {
    const registrationInfo = { email, firstName, lastName, password, confirmPassword };
    dispatch(validateRegistrationInformation(registrationInfo));
  };

  return (
    authStatus === AUTH_NOT_VERIFIED ? (<Redirect to='/verify' />) :
      authStatus === AUTH_IN_PROGRESS ? (<Redirect to='/termsOfService' />) :
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                Register
                </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={(e) => { e.preventDefault(); }}>
              <ErrorMessage errorMessage={errorMessage} />
              <LoadingIndicator />
              <IonList lines="full">
                <IonItem>
                  <IonLabel position="stacked"><strong>Email </strong><IonText color="danger">*</IonText></IonLabel>
                  <IonInput required name="email" type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked"><strong>First Name </strong><IonText color="danger">*</IonText></IonLabel>
                  <IonInput required name="firstName" type="text" value={firstName} onIonChange={(e) => setFirstName(e.detail.value!)} />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked"><strong>Last Name </strong><IonText color="danger">*</IonText></IonLabel>
                  <IonInput required name="lastName" type="text" value={lastName} onIonChange={(e) => setLastName(e.detail.value!)} />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked"><strong>Password </strong><IonText color="danger">*</IonText></IonLabel>
                  <IonInput required name="password" type="password" value={password} onIonChange={(e) => setPassword(e.detail.value!)} />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked"><strong>Confirm Password </strong><IonText color="danger">*</IonText></IonLabel>
                  <IonInput required name="password" type="password" value={confirmPassword} onIonChange={(e) => setConfirmPassword(e.detail.value!)} />
                </IonItem>
              </IonList>
                <IonButton class="ion-margin" expand="block" onClick={registerNext}>Register</IonButton>
              </form>
              <div className="ion-text-center"><IonText>Already registered? </IonText><IonRouterLink href="/login" color="dark"><strong>Log in</strong></IonRouterLink></div>
            </IonContent>
          </IonPage>
  )
};

export default Registration;
