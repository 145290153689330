import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { createAsyncRequest } from '../utils';

export const getOrganization = createAsyncThunk('ORGANIZATION_GET',
  createAsyncRequest((organizationId: number) => {
    return api.get(`organization/${organizationId}`);
  })
);
