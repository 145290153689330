import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AppDispatch } from '../store/store';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  IonBackButton,
  IonFooter,
  IonMenuButton,
  IonText,
} from "@ionic/react";
import { add, createOutline, trashOutline } from 'ionicons/icons';

import { Tabs, Tab } from 'react-bootstrap';

import { getGroups, deleteGroup, setGroupEditModalOpen } from '../features/groups/actions';
import { setChannelEditModalOpen, getChannels } from '../features/channels/actions';
import { channelsSelector } from '../features/channels/selectors';
import { authSelector } from '../features/auth/selectors';
import ChannelList from '../components/ChannelList';
import ChannelEdit from '../components/ChannelEdit';
import GroupEdit from '../components/GroupEdit';
import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import Menu from '../components/Menu';
import SecondaryMenu from '../components/SecondaryMenu';

const Channels: React.FC = () => {
  const { groupId } = useParams();
  const { user } = useSelector(authSelector);
  const { errorMessage, channels, joinedChannels, channelEditModalOpen, group, groupEditModalOpen } = useSelector(channelsSelector(groupId as number));
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getGroups(1));
    dispatch(getChannels(groupId));
  }, [dispatch, groupId]);

  const deleteGroupHandler = () => {
    dispatch(deleteGroup(groupId)).then(() => {
      history.push('/home');
    });
  };

  const secondaryMenuItems: any[] = [];
  if (user.canEditGroups) {
    secondaryMenuItems.push({ text: 'Edit Group', icon: createOutline, handler: () => dispatch(setGroupEditModalOpen(true))});
  }
  if (user.canDeleteGroups) {
    secondaryMenuItems.push({ text: 'Delete Group', icon: trashOutline, color: 'danger', handler: deleteGroupHandler});
  }

  return (!group ? null :
    <div>
      <Menu contentId="channels" />
      <IonPage id="channels">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/home" />
              <IonMenuButton />
            </IonButtons>
            <IonTitle class="page-title">
              {group ? group.name : ''}
            </IonTitle>
            {!!secondaryMenuItems.length && (
              <SecondaryMenu items={secondaryMenuItems} />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent class="has-bottom-tab-bar">
          <LoadingIndicator />
          <IonModal isOpen={groupEditModalOpen} cssClass='my-custom-class'>
            <GroupEdit group={group} />
          </IonModal>
          <IonModal isOpen={channelEditModalOpen} cssClass='my-custom-class'>
            <ChannelEdit groupId={groupId} />
          </IonModal>
          <ErrorMessage errorMessage={errorMessage} />
          <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
            <Tab eventKey="all" title="All Prayer Requests">
              {!!channels.length ? (
                <ChannelList groupId={groupId} channels={channels} />
              ): (
                <IonText class="empty-page-message" color="dark">
                  <p>There aren't any prayer requests created yet for {group.name}.<br /><br />To create a new prayer request, please contact a church administrator.</p>
                </IonText>  
              )}
            </Tab>
            <Tab eventKey="joined" title="Subscribed Prayer Requests">
              {!!joinedChannels.length ? (
                <ChannelList groupId={groupId} channels={channels} />
              ): (
                <IonText class="empty-page-message" color="dark">
                  <p>You haven't subscribed to any prayer requests for {group.name} yet.<br /><br />To subscribe, go to a prayer request, click on the top, right-hand menu, and click "Subscribe".</p>
                </IonText>  
              )}
              <ChannelList groupId={groupId} channels={joinedChannels} />
            </Tab>
          </Tabs>
        </IonContent>
        {user.canCreateChannels && (
          <IonFooter mode="ios">
            <IonToolbar>
              <IonButton expand="block" fill="outline" onClick={() => dispatch(setChannelEditModalOpen(true))}>
                <IonIcon slot="start" icon={add} />
                Add new prayer request
              </IonButton>
            </IonToolbar>
          </IonFooter>
        )}
      </IonPage>
    </div>
  )
};

export default Channels;
