import React, { useState, useEffect, } from 'react';
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonRow,
  IonCol,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonAlert,
  IonMenuButton,
  IonButtons,
  IonIcon,
  IonModal,
  IonBadge,
  IonText,
} from '@ionic/react';
import LoadingIndicator from '../components/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../features/auth/selectors';
import { add, lockClosedOutline, lockOpenOutline } from 'ionicons/icons';
import { getGroups, setGroupEditModalOpen } from '../features/groups/actions';
import { groupsSelector } from '../features/groups/selectors';
import { groupAccessRequestCountSelector } from '../features/groupAccessRequests/selectors';
import { getGroupAccessRequests } from '../features/groupAccessRequests/actions';
import GroupEdit from '../components/GroupEdit';
import RequestGroupAccess from '../components/RequestGroupAccess';
import Menu from '../components/Menu';
import './Home.css';

const Home: React.FC = () => {
  const { user } = useSelector(authSelector);
  const { groups, modalOpen } = useSelector(groupsSelector);
  const { groupAccessRequestCount } = useSelector(groupAccessRequestCountSelector)
  const dispatch = useDispatch();
  const organization = {
    id: 1,
    name: 'Highland Park Baptist Church',
  };

  useEffect(() => {
    // Currently org id 1 is on the only org, but this will have to be pulled from state when we have multiple orgs
    dispatch(getGroups(organization.id));
    dispatch(getGroupAccessRequests(organization.id));
  }, [dispatch, organization.id]);

  const [showAlert, setShowAlert] = useState(false);
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
  const [accessRequestGroup, setAccessRequestGroup] = useState();

  const lockedGroupClickHandler = groupId => {
    setAccessRequestGroup(groupId);
    setShowRequestAccessModal(true);
  }

  return (
    <div>
      <Menu contentId="home" />
      <IonPage id="home">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              {user.canViewGroupAccessRequests && !!groupAccessRequestCount && (
                <IonBadge mode="ios" slot="end" class="menu-button-badge">{groupAccessRequestCount}</IonBadge>
              )}
            </IonButtons>
            <IonTitle class="page-title">Highland Park Baptist Church</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <LoadingIndicator />
          <IonModal isOpen={modalOpen} onDidDismiss={() => dispatch(setGroupEditModalOpen(false))}>
            <GroupEdit organizationId={1} />
          </IonModal>
          <IonModal isOpen={showRequestAccessModal} cssClass="request-group-access-modal" onDidDismiss={() => setShowRequestAccessModal(false)}>
            <RequestGroupAccess groupId={accessRequestGroup} setShowRequestAccessModal={setShowRequestAccessModal} />
          </IonModal>
          {!!groups.length ? groups.map((group: any) => {
            const isPrivate = group.visibility === 'private';
            const locked = isPrivate && !group.role && !user.canViewPrivateGroups;
            return (
              <IonCard
                mode="ios"
                key={group.id}
                button={true}
                class={locked ? 'group-locked' : ''}
                routerLink={locked ? undefined : `/groups/${group.id}`}
                onClick={locked ? () => lockedGroupClickHandler(group.id) : undefined}
              >
                <IonCardHeader>
                  <IonRow class="ion-align-items-center">
                    <IonCol size="3">
                      <IonButton expand="block">
                        {isPrivate && locked && <IonIcon slot="icon-only" icon={lockClosedOutline} />}
                        {isPrivate && !locked && <IonIcon slot="icon-only" icon={lockOpenOutline} />}
                      </IonButton>
                    </IonCol>
                    <IonCol>
                      <IonCardTitle>{group.name}</IonCardTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent>
                  {group.description}
                </IonCardContent>
              </IonCard>
            );
          }) : (
            <IonText class="empty-page-message" color="dark">
              <p>There aren't any groups created yet for {organization.name}<br /><br />To create a new group, please contact a church administrator</p>
            </IonText>
          )}
          {/* <IonButton mode="ios" expand="block" color="primary" class="ion-margin" onClick={() => setShowAlert(true)}>Invite Friends</IonButton> */}
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass='my-custom-class'
            header={'Invite Friends'}
            subHeader={'Click the button to copy link and send via email or text'}
            message={window.location.href}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel');
                }
              },
              {
                text: 'Copy',
                handler: () => {
                  navigator.clipboard.writeText(window.location.href);
                  console.log('Confirm Copy');
                }
              }
            ]}
          />
        </IonContent>
        {user.canCreateGroups && (
          <IonFooter mode="ios">
            <IonToolbar>
              <IonButton expand="block" fill="outline" onClick={() => dispatch(setGroupEditModalOpen(true))}>
                <IonIcon slot="start" icon={add} />
                Add new group
              </IonButton>
            </IonToolbar>
          </IonFooter>
        )}
      </IonPage>
    </div>
  );
};

export default Home;
