import React from 'react';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';

import { Channel } from '../models/Channel';
import { useHistory } from "react-router-dom";
import '../resources/styles/ChannelCard.scss';

const ChannelCard = ({ groupId, channel }: { groupId: number, channel: Channel }) => {
  const history = useHistory();
  const goToDetail = () => {
    history.push(`/groups/${groupId}/channel/${channel.id}`);
  };

  return (
    <IonCard onClick={goToDetail}>
      <IonCardHeader>
        <IonCardTitle>{channel.name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent class="truncate-lines">{channel.description}</IonCardContent>
    </IonCard>
  );
};

export default ChannelCard;
