import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'react-router-redux';

import auth from '../features/auth/reducer';
import channels from '../features/channels/reducer';
import groups from '../features/groups/reducer';
import s3 from '../features/s3/reducer';
import users from '../features/users/reducer';
import clips from '../features/clips/reducer';
import organizations from '../features/organizations/reducer';
import groupAccessRequests from '../features/groupAccessRequests/reducer';
import { createBrowserHistory } from 'history';
import { routeApiMiddleware } from './apiMiddleware.js';


export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    router: connectRouter(history) as any,
    auth: auth.reducer,
    channels: channels.reducer,
    s3: s3.reducer,
    users: users.reducer,
    clips: clips.reducer,
    groups: groups.reducer,
    organizations: organizations.reducer,
    groupAccessRequests: groupAccessRequests.reducer,
  },
  middleware: getDefaultMiddleware().concat(routerMiddleware(history)).concat(routeApiMiddleware)
});

export default store;

export type AppDispatch = typeof store.dispatch;
