import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router";

import { AUTH_YES, AUTH_VERIFIED, AUTH_NOT_CHECKED } from '../features/auth/consts';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonRouterLink,
  IonText,
} from "@ionic/react";

import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import { authSelector } from '../features/auth/selectors';
import { login, clearErrors } from '../features/auth/actions';

const Login: React.FC = () => {
  const { authStatus, errorMessage, redirect } = useSelector(authSelector);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const submitLogin = () => dispatch(login({ email, password }));


  useEffect(() => {
    dispatch(clearErrors(null));
  }, [dispatch]);

  if (authStatus === AUTH_NOT_CHECKED) {
    return null;
  }

  if (authStatus === AUTH_YES) {
    return <Redirect to={(redirect && redirect !== '/login') ? redirect : '/home'} />
  }

  return (<IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          Login
          </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <LoadingIndicator />
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <IonList lines="full">
          <ErrorMessage errorMessage={errorMessage} />
          {authStatus === AUTH_VERIFIED && <IonItem>
            <IonLabel>Account Verified. Please Log in.</IonLabel>
          </IonItem>}

          <IonItem>
            <IonLabel position="stacked"><strong>Email:</strong></IonLabel>
            <IonInput name="email" type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><strong>Password:</strong></IonLabel>
            <IonInput name="password" type="password" value={password} onIonChange={(e) => setPassword(e.detail.value!)} />
          </IonItem>
        </IonList>
        <IonButton expand="block" onClick={submitLogin} class="ion-margin">Log in</IonButton>
        <div className="ion-text-center"><IonText>Not on Exalt yet? </IonText><IonRouterLink href="/registration" color="dark"><strong>Sign Up</strong></IonRouterLink></div>
        <div className="ion-text-center"><IonButton routerLink="/forgotPassword" fill="clear" size="small" color="dark"><strong>Forgot Password?</strong></IonButton></div>
      </form>
    </IonContent>
  </IonPage>
  )
};

export default Login;
