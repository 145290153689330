import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import RecordRTC from 'recordrtc';
import { setChannelEditModalOpen } from '../features/channels/actions';
import { AppDispatch } from '../store/store';
import { useHistory } from 'react-router-dom';
import '../resources/styles/ChannelDetail.css';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonButton,
  IonList,
  IonItem,
  IonButtons,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonBackButton,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonListHeader,
  IonFooter,
  IonModal,
  IonAlert,
  IonicSafeString,
  IonMenuButton,
} from "@ionic/react";
import { playCircle, pauseCircle, close, reloadCircle, trashOutline, exitOutline, createOutline, stopCircle, micOutline, ellipse, enterOutline } from 'ionicons/icons';

import ChannelEdit from '../components/ChannelEdit';
import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import { channelDetailSelector } from '../features/channels/selectors';
import {
  getChannels,
  joinChannel as joinChannelRequest,
  leaveChannel as leaveChannelRequest,
  deleteChannel as deleteChannelRequest,
} from '../features/channels/actions';

import {
  getChannelClips,
  deleteClip as deleteClipAction,
  confirmClipUpload
} from '../features/clips/actions';

import { getChannelUsers } from '../features/users/actions';
import { uploadAudio } from '../features/s3/actions';

import moment from 'moment';
import '../resources/styles/ChannelDetail.css';
import { authSelector } from '../features/auth/selectors';
import { unwrapResult } from '@reduxjs/toolkit';
import Menu from '../components/Menu';
import SecondaryMenu from '../components/SecondaryMenu';

const ChannelDetail: React.FC = () => {
  const MAX_PRAYER_DURATION = 30100;
  const { groupId, channelId } = useParams();
  const { channel, clips, modalOpen, errorMessage } = useSelector(channelDetailSelector(channelId as number));
  const { user } = useSelector(authSelector);
  const [blobUrl, setBlobUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordStartTime, setRecordStartTime] = useState(0);
  const [listenDuration, setListenDuration] = useState(0);
  const [listenStartTime, setListenStartTime] = useState(0);
  const [playState, setPlayState] = useState('stopped');
  const [timeRemaining, setTimeRemaining] = useState(MAX_PRAYER_DURATION);
  const [prayerRecord, setPrayerRecord] = useState(undefined as any);
  const [recordDuration, setRecordDuration] = useState(0);
  const [isRecordingSupported, setIsRecordingSupported] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const prayerAudio = useRef(undefined as any);
  const recordAudio = useRef(undefined as any);
  const prayerIdx = useRef(0);
  const timeouts = useRef([null as any]);

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && !isRecordingSupported) {
    setIsRecordingSupported(true);
  }

  console.log('rerender channel detail');
  const startRecording = () => {
    if (isRecording) {
      return;
    }
    const params = { audio: true };
    navigator.mediaDevices.getUserMedia(params).then(stream => {
      const _prayerRecord = RecordRTC(stream, { type: 'audio', recorderType: RecordRTC.StereoAudioRecorder });
      _prayerRecord.startRecording();
      setRecordStartTime(Date.now());
      setIsRecording(true);
      setPrayerRecord(_prayerRecord);
    }).catch(err => {
      console.error('Failed to getUserMedia', err);
      setShowAlert(true);
    });
  };

  const stopRecording = useCallback(() => {
    console.log('stop recording');
    if (!isRecording) {
      return;
    }
    setIsRecording(false);
    prayerRecord.stopRecording(() => {
      setRecordDuration(Date.now() - recordStartTime);
      setBlobUrl(URL.createObjectURL(prayerRecord.blob));
    });
  }, [isRecording, prayerRecord, recordStartTime]);

  useEffect(() => {
    if (isRecording) {
      timeouts.current[0] = setTimeout(() => {
        const timeElapsed = Date.now() - recordStartTime;
        if (timeElapsed > MAX_PRAYER_DURATION) {
          stopRecording();
          return;
        }
        setTimeRemaining(MAX_PRAYER_DURATION - timeElapsed);
      }, 1000);
    }
  }, [isRecording, stopRecording, timeouts, recordStartTime, timeRemaining]);

  const submitRecording = () => {
    const file = new File([prayerRecord.blob], '' + new Date().valueOf(), { type: "audio/wav" });
    dispatch(uploadAudio({ channelId: channel.id, file, duration: recordDuration })).then(unwrapResult).then(({ clipId }) => {
      dispatch(confirmClipUpload(clipId)).then(() => {
        dispatch(getChannelClips(channelId));
      });
      deleteRecording();
    });
  };

  const joinChannel = () => {
    dispatch(joinChannelRequest(channelId)).then(() => {
      dispatch(getChannels(groupId));
    });
  };

  const leaveChannel = () => {
    dispatch(leaveChannelRequest(channelId)).then(() => {
      dispatch(getChannels(groupId));
    });
  };

  const deleteChannel = () => {
    dispatch(deleteChannelRequest(channelId)).then(() => {
      dispatch(getChannels(groupId)).then(() => {
        history.goBack();
      });
    });
  };

  const onPrayerAudioEnded = () => {
    if (listenDuration === 0 || Date.now() < listenStartTime + listenDuration) {
      prayerIdx.current = prayerIdx.current + 1;
      playNext();
    } else {
      setPlayState('stopped');
    }
  };

  const playClips = () => {
    if (playState === 'paused') {
      setPlayState('play');
      prayerAudio.current.play();
      return;
    }

    if (!prayerAudio) {
      console.log('no prayer audio');
      return;
    }

    if (prayerIdx.current >= clips.length) {
      console.log('Reached end of prayers');
      return;
    }

    setPlayState('play');
    setListenStartTime(Date.now());
    playNext();
  };

  const playNext = () => {
    if (prayerIdx.current >= clips.length) {
      console.log('out of prayers');
      setPlayState('stopped');
      return;
    }
    prayerAudio.current.src = clips[prayerIdx.current].url;
    prayerAudio.current.currentTime = 0;
    prayerAudio.current.play();
  };

  const pauseClip = () => {
    prayerAudio.current.pause();
    setPlayState('paused');
  }

  const deleteRecording = () => {
    setPrayerRecord(null);
  };

  const restartClips = () => {
    prayerIdx.current = 0;
    playClips();
  };

  const deleteClip = (clipId: number) => {
    dispatch(deleteClipAction(clipId));
  };

  useEffect(() => {
    let timeout = timeouts.current;
    return () => {
      if (timeout[0]) {
        clearTimeout(timeout[0]);
        console.log('cleared timeout');
      }
      console.log("cleaned up");
    };
  }, []);

  useEffect(() => {
    dispatch(getChannels(groupId))
    dispatch(getChannelClips(channelId));
    dispatch(getChannelUsers(channelId));
  }, [dispatch, groupId, channelId]);

  const secondaryMenuItems: any[] = [];
  if (channel && !channel.subscribed) {
    secondaryMenuItems.push({ text: 'Subscribe', icon: enterOutline, handler: joinChannel});
  } else if (channel) {
    secondaryMenuItems.push({ text: 'Unsubscribe', icon: exitOutline, handler: leaveChannel});
  }
  if (user.canEditChannels) {
    secondaryMenuItems.push({ text: 'Edit Request', icon: createOutline, handler: () => dispatch(setChannelEditModalOpen(true))});
  }
  if (user.canDeleteChannels) {
    secondaryMenuItems.push({ text: 'Delete Request', icon: trashOutline, color: 'danger', handler: deleteChannel});
  }

  return (!channel ? null :
    <div>
      <Menu contentId="channel-details" />
      <IonPage id="channel-details">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref={`/groups/${groupId}`} />
              <IonMenuButton />
            </IonButtons>
            {!isRecording && <IonTitle class="page-title">
              Prayer Request
            </IonTitle>}
            {isRecording && <IonTitle class="page-title">
              Prayer Request (Recording)
            </IonTitle>}
            {!!secondaryMenuItems.length && (
              <SecondaryMenu items={secondaryMenuItems} />
            )}
          </IonToolbar>
        </IonHeader>
        <IonModal isOpen={modalOpen} cssClass='my-custom-class' onDidDismiss={() => dispatch(setChannelEditModalOpen(false))}>
          <ChannelEdit groupId={groupId} channel={channel} />
        </IonModal>
        <ErrorMessage errorMessage={errorMessage} />
        <LoadingIndicator />

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="no-microphone-alert"
          header="Can't Access Microphone!"
          message={new IonicSafeString(`<div>
            <p><strong>Chrome:</strong> Go to "Settings" > "Privacy and Security" > "Site Settings" > "Microphone" and remove exaltprayers.com from the list of blocked sites</p>
            <p><strong>Safari:</strong> Go to "Preferences" > "Websites" > "Microphone" and allow access to exaltprayers.com</p>
          </div>`)}
          buttons={['OK']}
        />
        <IonList>
          <IonListHeader>
            {channel.name}
          </IonListHeader>
          <IonItem lines="none">
            <IonCardSubtitle>{channel && moment(channel.createdAt).format('MMMM DD YYYY')}</IonCardSubtitle>
          </IonItem>
          <IonItem lines="none">
            <IonText class="content hide-content">{channel && channel.description}</IonText>
          </IonItem>
        </IonList>
        <IonItem lines="none">
          <IonList>
            {clips.length !== 0 && !isRecording && !prayerRecord?.blob && <IonToolbar class="ion-padding-top" mode="ios">
              <IonLabel class="ion-padding-horizontal">Playback<br></br>Duration</IonLabel>
              <audio ref={prayerAudio} onEnded={onPrayerAudioEnded} />
              <IonSegment mode="ios">
                <IonSegmentButton value="5min" onClick={() => setListenDuration(5 * 60 * 1000)}>5 min</IonSegmentButton>
                <IonSegmentButton value="10min" onClick={() => setListenDuration(10 * 60 * 1000)}>10 min</IonSegmentButton>
                <IonSegmentButton value="all" onClick={() => setListenDuration(0)}>All</IonSegmentButton>
              </IonSegment>
            </IonToolbar>}
            {playState !== 'play' && prayerIdx.current < clips.length && listenDuration === 0 && !prayerRecord?.blob && !isRecording && <IonButton mode="ios" color="dark" expand="block" size="large" fill="outline" onClick={playClips}>
              <IonIcon slot='start' size="large" icon={playCircle} /> Listen to All Prayers
                </IonButton>}
            {playState !== 'play' && prayerIdx.current < clips.length && listenDuration === 5 * 60 * 1000 && !prayerRecord?.blob && !isRecording && <IonButton mode="ios" color="dark" expand="block" size="large" fill="outline" onClick={playClips}>
              <IonIcon slot='start' size="large" icon={playCircle} /> Listen to Prayers for 5 min
                </IonButton>}
            {playState !== 'play' && prayerIdx.current < clips.length && listenDuration === 10 * 60 * 1000 && !prayerRecord?.blob && !isRecording && <IonButton mode="ios" expand="block" color="dark" size="large" fill="outline" onClick={playClips}>
              <IonIcon slot='start' size="large" icon={playCircle} /> Listen to Prayers for 10 min
                </IonButton>}
            {playState !== 'play' && prayerIdx.current >= clips.length && clips.length !== 0 && !prayerRecord?.blob && <IonButton mode="ios" expand="block" size="large" fill="outline" color="dark" onClick={restartClips}>
              <IonIcon slot='icon-only' size="large" icon={reloadCircle} /> Listen Again
              </IonButton>}
            {playState === 'play' && listenDuration !== 0 && <IonButton mode="ios" expand="block" size="large" color="dark" fill="outline" onClick={pauseClip}>
              <IonIcon slot='icon-only' size="large" icon={pauseCircle} /> Listening to {listenDuration / 60000}:00 of Prayers
              </IonButton>}
            {playState === 'play' && listenDuration === 0 && <IonButton mode="ios" expand="block" size="large" color="dark" fill="outline" onClick={pauseClip}>
              <IonIcon slot='icon-only' size="large" icon={pauseCircle} /> Listening to All Prayers
              </IonButton>}
          </IonList>
        </IonItem>
        <IonContent>
          <IonItem lines="none">
            {!isRecording && !prayerRecord?.blob && <div style={{ height: '100%', overflowY: 'scroll' }}>
              {clips?.map((clip: any) => (
                <IonCard id="recordings" mode="ios" key={clip.id}>
                  <IonCardHeader class="ion-no-padding ion-padding-horizontal ion-padding-top">
                    <IonCardSubtitle>
                      {`${clip.User.firstName} ${clip.User.lastName} - ${moment(clip.createdAt).format('MMMM DD, h:mm a')}`}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <div className="ion-text-center"><IonText mode="ios">
                    {`Duration: ${Math.round(clip.duration / 100) / 10.0} seconds`}
                    {(clip.userId === user.id || user.canDeleteClips) && <IonButton mode="ios" class="ion-margin-bottom" fill="clear" color="danger" onClick={() => deleteClip(clip.id)}>
                      <IonIcon slot='icon-only' icon={close} />
                    </IonButton>}
                    {clip.userId !== user.id && <IonButton disabled fill="clear" class="ion-margin-bottom"></IonButton>}
                  </IonText>
                  </div>
                </IonCard>
              ))}
            </div>}
          </IonItem>
          {playState !== 'play' && clips.length === 0 && !isRecording && !prayerRecord?.blob && (
            <div id="no-prayers" className="ion-text-center">
              <IonText color="medium">
                <h3>No Prayers Yet</h3>
              </IonText>
            </div>
          )}
          {isRecording && (
            <IonCard color="light" class="new-recording">
              <IonItem color="light" lines="none">
                <IonText> <IonIcon color="danger" icon={ellipse} /> Recording</IonText>
                <IonText slot="end" color="medium" onClick={stopRecording}>CANCEL X</IonText>
              </IonItem>
              <div id="time-remaining">
                <IonIcon id="mic" icon={micOutline} size="large" />
                <IonItem color="light" lines="none"><IonLabel class="ion-text-center">0:{Math.round(timeRemaining / 1000)} <IonText color="medium">of 0:30 remaining</IonText></IonLabel></IonItem>
              </div>
            </IonCard>
          )}
          {prayerRecord?.blob && !isRecording && (
            <IonCard color="light" class="new-recording">
              <IonItem color="light" lines="none">
                <IonText>Recording</IonText>
                <IonText slot="end" color="medium" onClick={deleteRecording}>CANCEL X</IonText>
              </IonItem>
              <div id="time-remaining">
                <IonIcon id="mic" icon={micOutline} size="large" />
                <IonItem color="light" lines="none"><IonLabel class="ion-text-center">0:{Math.round(timeRemaining / 1000)} <IonText color="medium">of 0:30 remaining</IonText></IonLabel></IonItem>
              </div>
            </IonCard>
          )}
          {prayerRecord?.blob && !isRecording && (
            <IonCard color="light" class="new-recording">
              <IonItem color="light" lines="none">
                <IonText>Recording</IonText>
                <IonText slot="end" color="medium" onClick={deleteRecording}>CANCEL X</IonText>
              </IonItem>
              <IonItem color="light" class="ion-text-align" lines="none"><audio id="recorded-audio" ref={recordAudio} controls={true}><source src={blobUrl} /></audio></IonItem>
            </IonCard>
          )}
        </IonContent>
        <IonFooter class="ion-padding-bottom">
          {isRecording && <IonButton expand="block" fill="outline" color="danger" class="ion-margin-horizontal" onClick={stopRecording}><IonIcon slot='start' size="large" icon={stopCircle} /> Stop Recording</IonButton>}
          {!isRecordingSupported && <IonButton expand="block" class="ion-margin-horizontal" disabled>No microphone detected</IonButton>}
          {isRecordingSupported && !isRecording && prayerRecord?.blob && <IonButton expand="block" fill="outline" color="dark" class="ion-margin-horizontal" onClick={startRecording}>Record Again</IonButton>}
          {/* {isRecordingSupported && !isRecording && prayerRecord?.blob && <IonButton expand="block" fill="outline" color="danger" class="ion-margin-horizontal" onClick={deleteRecording}>Delete Recording</IonButton>} */}
          {isRecordingSupported && !isRecording && prayerRecord?.blob && <IonButton expand="block" fill="outline" color="dark" class="ion-margin-horizontal" onClick={submitRecording}>Publish Prayer</IonButton>}
          {isRecordingSupported && !isRecording && !prayerRecord?.blob && <IonButton fill="outline" color="dark" expand="block" class="ion-margin-horizontal" onClick={startRecording}>Add Prayer</IonButton>}
        </IonFooter>
      </IonPage>
    </div>
  )
};

export default ChannelDetail;

