import { reduce } from 'ramda';

export const groupAccessRequestSelector = (groupId) => state => {
  const groupAccessRequest = state.groupAccessRequests.requests.find(accessRequest => {
    return accessRequest.userId === state.auth.user.id && accessRequest.groupId === groupId;
  });

  return {
    loading: state.groupAccessRequests.loading,
    error: state.groupAccessRequests.error,
    groupAccessRequest,
  };
};

export const groupAccessRequestCountSelector = state => ({
  groupAccessRequestCount: state.groupAccessRequests.requests.length,
});

export const groupSelector = state => {
  const groupsWithRequests = reduce((groups, request) => {
    if (!state.groups.groups[request.groupId]) {
      return groups;
    }

    if (!groups[request.groupId]) {
      // individual groups are immutable from state, so doing deep clone
      groups[request.groupId] = JSON.parse(JSON.stringify(state.groups.groups[request.groupId]));
      groups[request.groupId].requests = []
    }
    groups[request.groupId].requests.push(request);
    return groups;
  }, {}, state.groupAccessRequests.requests);

  return {
    groupsWithRequests: Object.values(groupsWithRequests).sort((a, b) => a.name < b.name ? -1 : 1)
  };
};
