import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { logOutOutline, lockOpenOutline, homeOutline } from 'ionicons/icons';

import { setAuthStatus } from '../features/auth/actions';
import { authSelector } from '../features/auth/selectors';
import { AUTH_NO } from '../features/auth/consts';
import { getGroupAccessRequests } from '../features/groupAccessRequests/actions';
import { groupAccessRequestCountSelector } from '../features/groupAccessRequests/selectors';
import './Menu.css';

const Menu = ({ contentId }: { contentId: string }) => {
  const { user } = useSelector(authSelector);
  const { groupAccessRequestCount } = useSelector(groupAccessRequestCountSelector)
  const dispatch = useDispatch();

  const logout = () => {
    console.log('logout');
    localStorage.removeItem('authToken');
    dispatch(setAuthStatus(AUTH_NO));
  };

  useEffect(() => {
    dispatch(getGroupAccessRequests(false));
  }, [dispatch]);

  return (
    <IonMenu side="start" menuId="main" contentId={contentId}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Hi, {user.firstName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="full" class="menu-list">
          <IonItem href="/home"><IonIcon slot="start" icon={homeOutline} />Home</IonItem>
          {user.canViewGroupAccessRequests && (
            <IonItem href="/admin">
              <IonIcon slot="start" icon={lockOpenOutline} />
              Group Access Requests
              <IonBadge mode="ios" slot="end">{groupAccessRequestCount}</IonBadge>
            </IonItem>
          )}
          <IonItem onClick={logout}><IonIcon slot="start" icon={logOutOutline} />Logout</IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
