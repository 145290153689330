import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from '../components/LoadingIndicator';

import { authSelector } from '../features/auth/selectors';
import { forgotPassword } from '../features/auth/actions';
import { AppDispatch } from '../store/store';

const ForgotPassword: React.FC = () => {
  const { errorMessage } = useSelector(authSelector);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const sendPasswordResetLink = () => {
    console.log('sent password reset link');
    dispatch(forgotPassword(email)).then(() => {
      setMessage('Password Reset Link sent to email');
    });
  };

  return (<IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          Forgot Password
        </IonTitle>
        <IonButtons slot="start">
          <IonBackButton defaultHref='/login' />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <ErrorMessage errorMessage={errorMessage} />
      <LoadingIndicator />
      {message && <IonLabel class="ion-padding">{message}</IonLabel>}
      <form onSubmit={(e) => { e.preventDefault() }}>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked"><strong>Email: </strong></IonLabel>
            <IonInput required name="email" type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} />
          </IonItem>
        </IonList>
        <IonButton expand="block" class="ion-margin" onClick={sendPasswordResetLink}>Send Password Reset Link</IonButton>
      </form>
    </IonContent>
  </IonPage>
  )
};

export default ForgotPassword;
