import { createSlice } from '@reduxjs/toolkit'

import { getChannelClips, deleteClip, confirmClipUpload } from './actions';
import { omit, mergeDeepRight } from 'ramda';
import { mapToObjectWithIdKey } from '../utils';

const initialState = {
  error: {},
  loading: false,
  clips: {}
};

const reducer = createSlice({
  name: 'clips',
  initialState,
  reducers: {},
  extraReducers: {
    [getChannelClips.pending]: state => {
      state.loading = true;
    },
    [getChannelClips.fulfilled]: (state, action) => {
      state.loading = false;
      state.clips = mergeDeepRight(state.clips, mapToObjectWithIdKey(action.payload.clips));
    },
    [getChannelClips.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteClip.pending]: state => {
      state.loading = true;
    },
    [deleteClip.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      const deletedClipId = action.meta.arg;
      state.clips = omit([deletedClipId], state.clips);
    },
    [deleteClip.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [confirmClipUpload.pending]: state => {
      state.loading = true;
    },
    [confirmClipUpload.fulfilled]: state => {
      state.loading = false;
      state.error = {};
    },
    [confirmClipUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  }
});

export default reducer;
