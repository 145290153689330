import React, { useState } from 'react';

import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonIcon,
  IonTextarea,
  IonText,
  IonPage
} from "@ionic/react";

import ErrorMessage from './ErrorMessage';
import { createChannel, setChannelEditModalOpen, updateChannel } from '../features/channels/actions';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelector } from '../features/channels/selectors';
import { closeOutline } from 'ionicons/icons';
import { Channel } from '../models/Channel';

const ChannelEdit = ({groupId, channel}: {groupId: number, channel?: Channel}) => {

  const { errorMessage } = useSelector(channelsSelector(groupId as number));
  const [name, setName] = useState(channel ? channel.name : '');
  const [description, setDescription] = useState(channel ? channel.description : '');
  const dispatch = useDispatch();
  const submitMethod = channel ? updateChannel : createChannel;
  const submitChannelDetails = () => dispatch(submitMethod({ channel, name, description, groupId }));
  const title = channel ? 'Edit Prayer Request' : 'New Prayer Request';
  const action = channel ? 'Update' : 'Create';

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => dispatch(setChannelEditModalOpen(false))}>
              <IonIcon icon={closeOutline} slot='icon-only'/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <IonList>
            <ErrorMessage errorMessage={errorMessage} />
            <IonItem lines="full">
              <IonLabel position="stacked"><strong>Title of Request</strong><IonText color="danger">*</IonText></IonLabel>
              <IonInput required name="name" type="text" value={name} onIonChange={(e) => setName(e.detail.value!)} />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked"><strong>Description</strong></IonLabel>
              <IonTextarea name="description" value={description} autoGrow={true} onIonChange={(e) => setDescription(e.detail.value!)} />
            </IonItem>
          </IonList>
          <IonButton class="ion-margin" expand="block" onClick={submitChannelDetails}>{action}</IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ChannelEdit;
