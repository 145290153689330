import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router";
import { authSelector } from '../features/auth/selectors';
import { text } from '../resources/termsOfService.json';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
  IonFooter,
} from "@ionic/react";
import LoadingIndicator from '../components/LoadingIndicator';
import TermsOfServiceContent from '../components/TermsOfServiceContent';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';

import { AUTH_IN_PROGRESS, AUTH_NO } from '../features/auth/consts';
import { register, setAuthStatus } from '../features/auth/actions';

const TermsOfService: React.FC = () => {
  const { authStatus, user } = useSelector(authSelector);

  const dispatch = useDispatch();
  const confirmRegister = () => {
    dispatch(register(user));
  };

  useEffect(() => {
    return () => {
      dispatch(setAuthStatus(AUTH_NO));
    };
  }, [dispatch])

  return (
    authStatus !== AUTH_IN_PROGRESS ? (<Redirect to='/registration' />) :
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle class="page-title">
              Terms of Service and Privacy
           </IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref='/registration' />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <LoadingIndicator />
          <TermsOfServiceContent />
          <PrivacyPolicyContent />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton class="ion-margin" expand="block" onClick={confirmRegister}>Accept</IonButton>
          </IonToolbar>
        </IonFooter>
      </IonPage>
  )
};

export default TermsOfService;
