import { createSlice } from '@reduxjs/toolkit'

import {
  AUTH_NOT_CHECKED,
  AUTH_NO,
  AUTH_YES,
  AUTH_NOT_VERIFIED,
  AUTH_VERIFIED,
  AUTH_IN_PROGRESS
} from './consts';

import { setAuthStatus, clearErrors, login, register, verify, getCurrentUser, validateRegistrationInformation, forgotPassword, resetPassword } from './actions';

const initialState = {
  status: AUTH_NOT_CHECKED,
  loading: false,
  error: {},
  user: {},
  redirect: ''
};

const reducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [setAuthStatus]: (state, action) => {
      state.status = action.payload.status;
    },
    [clearErrors]: (state) => {
      state.error = {};
    },
    [validateRegistrationInformation.pending]: state => {
      state.loading = true;
    },
    [validateRegistrationInformation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [validateRegistrationInformation.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
      state.status = AUTH_IN_PROGRESS;
    },
    [login.pending]: state => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = AUTH_YES;
      state.error = '';
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.status = AUTH_NO;
    },
    [register.pending]: (state, action) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = '';
      state.status = AUTH_NOT_VERIFIED;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [verify.pending]: state => {
      state.loading = true;
    },
    [verify.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = '';
      state.status = AUTH_VERIFIED;
    },
    [verify.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.status = AUTH_NO;
    },
    [getCurrentUser.pending]: state => {
      state.loading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = '';
      state.status = AUTH_YES;
      state.user = action.payload;
      console.log(`Redirecting to: ${action.meta.arg}`);
      state.redirect = action.meta.arg;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.status = AUTH_NO;
    },
    [forgotPassword.pending]: state => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = ''
    },
    [forgotPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [resetPassword.pending]: state => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = ''
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    }
  }
});

export default reducer;
