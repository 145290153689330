import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from '../features/auth/selectors';
import { AUTH_YES } from '../features/auth/consts';

const PrivateRoute = ({ component: Component, ...rest } : any) => {
  const { authStatus } = useSelector(authSelector);
  return (
    <Route {...rest} render={(props) => (
      authStatus === AUTH_YES
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
}

export default PrivateRoute;