import React from 'react';
import {
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonText,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { authSelector } from '../features/auth/selectors';
import { AUTH_NOT_CHECKED } from '../features/auth/consts';
import './Landing.css';

const bgImage = require('../resources/images/landing.jpg');

const Landing: React.FC = () => {
  const { authStatus } = useSelector(authSelector);

  if (authStatus === AUTH_NOT_CHECKED) {
    return null;
  }
  const text = 'Oh, magnify the LORD with me, and let us exalt his name together! Psalm 34:3 ESV';
  return (
    <IonPage>
      <IonContent class="landing-page-content" style={{'--background': 'bottom center / cover url(' + String(bgImage) + ') no-repeat'}}>
        <IonGrid class="landing-page-grid fullheight">
          <IonRow class="landing-page-header-row">
            <div className="landing-page-title-wrap">
              <h1>Exalt</h1>
              <h2>the Christian community that prays!</h2>
            </div>
          </IonRow>
          <IonRow class="landing-page-footer-row">
            <IonText color="dark">{text}</IonText>
            <div className="landing-page-buttons">
              <IonButton routerLink="/login" strong>Login</IonButton>
              <IonButton routerLink="/registration" strong>Register</IonButton>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Landing;
