import React from 'react';
import {
  IonItem,
  IonLabel,
} from '@ionic/react';

const ErrorMessage = (props: {
  errorMessage: string
}) => {
  return props.errorMessage ? (
    <IonItem>
      <IonLabel color="danger">{props.errorMessage}</IonLabel>
    </IonItem>
  ) : null;
};

export default ErrorMessage;
