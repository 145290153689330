import React, { useState } from 'react';

import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonIcon,
  IonTextarea,
  IonText,
  IonPage,
  IonSelect,
  IonSelectOption
} from "@ionic/react";

import ErrorMessage from '../components/ErrorMessage';
import { createGroup, updateGroup, setGroupEditModalOpen } from '../features/groups/actions';
import { useDispatch, useSelector } from 'react-redux';
import { groupsSelector } from '../features/groups/selectors';
import { closeOutline } from 'ionicons/icons';
import { Group } from '../models/Group';

const GroupEdit = ({organizationId, group}: {organizationId?: number, group?: Group}) => {

  const { errorMessage } = useSelector(groupsSelector);
  const [name, setName] = useState(group ? group.name : '');
  const [description, setDescription] = useState(group ? group.description : '');
  const [visibility, setVisibility] = useState(group ? group.visibility : 'public');
  const dispatch = useDispatch();
  const submitMethod = group ? updateGroup : createGroup;
  const submitGroupDetails = () => dispatch(submitMethod({ group, name, description, visibility, organizationId }));
  const title = group ? 'Edit Group' : 'New Group';
  const action = group ? 'Update' : 'Create';

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => dispatch(setGroupEditModalOpen(false))}>
              <IonIcon icon={closeOutline} slot='icon-only'/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <IonList>
            <ErrorMessage errorMessage={errorMessage} />
            <IonItem lines="full">
              <IonLabel position="stacked"><strong>Title of Group</strong><IonText color="danger">*</IonText></IonLabel>
              <IonInput required name="name" type="text" value={name} onIonChange={(e) => setName(e.detail.value!)} />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked"><strong>Description</strong></IonLabel>
              <IonTextarea name="description" auto-grow="true" value={description} onIonChange={(e) => setDescription(e.detail.value!)} />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked"><strong>Visibility</strong><IonText color="danger">*</IonText></IonLabel>
              <IonSelect value={visibility} okText="Okay" cancelText="Dismiss" onIonChange={e => setVisibility(e.detail.value!)}>
                <IonSelectOption value="public">Public</IonSelectOption>
                <IonSelectOption value="private">Private</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>
          <IonButton class="ion-margin" expand="block" onClick={submitGroupDetails}>{action}</IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default GroupEdit;
