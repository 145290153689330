import React from 'react';
import { useSelector } from 'react-redux';
import { loadingSelector } from '../features/loading/selectors';
import { IonLoading } from '@ionic/react';

const LoadingIndicator = () => {
  const { loading } = useSelector(loadingSelector);
  return <IonLoading isOpen={loading} />;
};

export default LoadingIndicator;