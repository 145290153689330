import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncRequest } from '../utils';
import api from '../../services/api';

export const createGroupAccessRequest = createAsyncThunk('GROUP_ACCESS_REQUEST_CREATE',
  async (args: { groupId?: number }) => {
    if (!args.groupId) {
      throw new Error('Group id is required');
    }
    const { data } = await api.post('groupAccessRequests/create', args);
    return data.groupAccessRequest;
  }
);

export const getGroupAccessRequests = createAsyncThunk('GROUP_ACCESS_REQUESTS_GET',
  createAsyncRequest(() => {
    return api.get('groupAccessRequests');
  })
);

export const grantGroupAccess = createAsyncThunk('GROUP_ACCESS_GRANT',
  createAsyncRequest(requests => {
    console.log('requests', requests);
    return api.post('groupAccessRequests/grant', { requests });
  })
);

export const denyGroupAccess = createAsyncThunk('GROUP_ACCESS_DENY',
  createAsyncRequest(requests => {
    return api.post('groupAccessRequests/deny', { requests });
  })
);
