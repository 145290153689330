import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import axios from 'axios';

export const uploadAudio = createAsyncThunk('S3_SIGN',
  async ({ channelId, file, duration }: { channelId: number, file: File, duration: number }) => {
    const { data: { clipId, signedRequest } } = await api.post('s3/sign', { channelId: channelId, fileType: file.type, duration });
    const options = {
      headers: {
        'Content-Type': file.type,
      }
    };
    await axios.put(signedRequest, file, options);
    return { clipId };
  }
);
