import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { createAsyncRequest } from '../utils';
import api from '../../services/api';
import { Channel } from '../../models/Channel';

export const createChannel = createAsyncThunk('CHANNEL_CREATE',
  async (args: { name: string, description: string, groupId: number }, thunkApi) => {
    if (!args.name) {
      throw new Error('Please enter a channel name.');
    }
    const { data } = await api.post('channels/create', args);
    if (!data.error) {
      thunkApi.dispatch(setChannelEditModalOpen(false));
    }
    return data.channel;
  }
);

export const updateChannel = createAsyncThunk('CHANNEL_UPDATE',
  async (args: { channel?: Channel, name: string, description: string, groupId: number }, thunkApi) => {
    if (!args.channel) {
      throw new Error('Missing channel id');
    }
    if (!args.name) {
      throw new Error('Please enter a prayer request name.');
    }
    const { data } = await api.post('channels/update', { channelId: args.channel.id, name: args.name, description: args.description });
    console.log('channel updated', data);
    if (!data.error) {
      thunkApi.dispatch(setChannelEditModalOpen(false));
    }
    return data.channel;
  }
);

export const getChannels = createAsyncThunk('CHANNELS_GET',
  createAsyncRequest((groupId: number) => {
    return api.get('channels', {
      params: {
        groupId
      }
    });
  })
);

export const setChannelEditModalOpen = createAction('CHANNEL_EDIT_MODAL_OPEN_SET', isOpen => {
  return { payload: { isOpen } };
});

export const joinChannel = createAsyncThunk('CHANNEL_JOIN',
  createAsyncRequest((channelId: number) => {
    return api.post('channels/join', {}, {
      params: {
        channelId
      }
    });
  })
);

export const leaveChannel = createAsyncThunk('CHANNEL_LEAVE',
  createAsyncRequest((channelId: number) => {
    return api.post('channels/leave', {}, {
      params: {
        channelId
      }
    });
  })
);

export const deleteChannel = createAsyncThunk('CHANNEL_DELETE',
  createAsyncRequest((channelId: number) => {
    return api.delete('channels/delete', {
      params: {
        channelId
      }
    });
  })
);
