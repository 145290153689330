import React from 'react';
import {
  IonList
} from '@ionic/react';
import ChannelCard from './ChannelCard';
import { Channel } from '../models/Channel';
import '../resources/styles/ChannelCard.scss';

const ChannelList = ({ groupId, channels }: { groupId: number, channels: Channel[] }) => {
  return (
    <div>
      <IonList class="hue">
        {channels.map(channel => {
          return (
            <ChannelCard groupId={groupId} channel={channel} key={channel.id} />
          );
        })}
      </IonList>
    </div>
  );
};

export default ChannelList;
