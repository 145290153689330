import axios from 'axios';


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api'
});

api.interceptors.response.use(null, e => {
  console.error('API Error', e);
  return Promise.reject(e.response.data);
});

api.defaults.withCredentials = true;

export const setAuthHeader = () => {
  const authToken = JSON.parse(localStorage.getItem('authToken'));

  if (authToken) {
    api.defaults.headers = { 'Authorization': 'Bearer ' + authToken };
  } else {
    return {};
  }
}

setAuthHeader();

export default api;