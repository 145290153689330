import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IonContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonFooter,
  IonButton,
} from '@ionic/react';
import { groupAccessRequestSelector } from '../features/groupAccessRequests/selectors';
import { createGroupAccessRequest } from '../features/groupAccessRequests/actions';
import './RequestGroupAccess.css';

const RequestGroupAccess = ({ groupId, setShowRequestAccessModal }: { groupId?: number, setShowRequestAccessModal }) => {
  const { groupAccessRequest } = useSelector(groupAccessRequestSelector(groupId as number));
  const dispatch = useDispatch();
  console.log('groupAccessRequest', groupAccessRequest);

  return !groupAccessRequest ? (
    <>
      <IonContent>
        <IonText color="dark">
          <h3 className="access-request-title">This is a private group</h3>
        </IonText>
        <IonText color="dark">
          <p className="access-request-body">You may request access to this group below</p>
        </IonText>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <IonButton expand="block" onClick={() => dispatch(createGroupAccessRequest({ groupId }))}>RequestAccess</IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton expand="block" fill="outline" color="medium" onClick={() => setShowRequestAccessModal(false)}>Cancel</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </>
  ) : (
    <>
      <IonContent>
        <IonText color="dark-tint">
          <h3 className="access-request-title">Your request has been submitted</h3>
        </IonText>
        <IonText color="dark">
          <p className="access-request-body">You will receive an email after your request has been reviewed</p>
        </IonText>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="3" />
              <IonCol size="6">
                <IonButton expand="block" fill="outline" color="medium" onClick={() => setShowRequestAccessModal(false)}>Ok</IonButton>
              </IonCol>
              <IonCol size="3" />
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </>
  )
};

export default RequestGroupAccess;
