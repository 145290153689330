import { LOCATION_CHANGE } from 'connected-react-router';
import { setAuthStatus, getCurrentUser } from '../features/auth/actions';
import { AUTH_NOT_CHECKED, AUTH_NO } from '../features/auth/consts';

export const routeApiMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE && store.getState().auth.status === AUTH_NOT_CHECKED) {
    const authToken = localStorage.getItem('authToken');
    console.log('Authenticating user...');
    if (authToken) {
      if (!store.getState().auth.loading) {
        const { pathname } = action.payload.location;
        store.dispatch(getCurrentUser(pathname));
      }
    } else {
      store.dispatch(setAuthStatus(AUTH_NO));
    }
    return next(action);
  }
  return next(action);
};