import * as R from 'ramda';

export const channelsSelector = groupId => state => {
  const group = state.groups.groups[groupId];

  return ({
    loading: state.channels.loading,
    errorMessage: state.channels.error.message,
    channels: R.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1), R.values(state.channels.channels)),
    joinedChannels: R.values(state.channels.channels)?.filter(channel => channel.subscribed),
    channelEditModalOpen: state.channels.modalOpen,
    groupEditModalOpen: state.groups.modalOpen,
    group,
  });
};

export const channelDetailSelector = channelId => state => {
  const channel = state.channels.channels[channelId];

  return ({
    loading: state.channels.loading,
    errorMessage: state.channels.error.message,
    modalOpen: state.channels.modalOpen,
    channel,
    // eslint-disable-next-line
    clips: R.values(state.clips.clips).filter(clip => clip.channelId == channelId),
  });
};
