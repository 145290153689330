import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import PrivateRoute from './components/PrivateRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Verify from './pages/Verify';
import Channels from './pages/Channels';
import ChannelDetail from './pages/ChannelDetail';
import TermsOfService from './pages/TermsOfService';
import Landing from './pages/Landing';
import Admin from './pages/Admin';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'bootstrap/dist/css/bootstrap.min.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Switch>
        <PrivateRoute path="/groups/:groupId/channel/:channelId" component={ChannelDetail} exact={true} />
        <PrivateRoute path="/groups/:groupId" component={Channels}/>
        <PrivateRoute path="/home" component={Home} exact={true} /> 
        <PrivateRoute path="/admin" component={Admin} exact={true} /> 
        {/* Login does its own redirection */}
        <Route path="/login" component={Login} exact={true} />
        <UnauthenticatedRoute path="/registration" component={Registration} exact={true} />
        <UnauthenticatedRoute path="/termsOfService" component={TermsOfService} exact={true} />
        <UnauthenticatedRoute path="/verify" component={Verify} />
        <UnauthenticatedRoute path="/landing" component={Landing}/>
        <UnauthenticatedRoute path='/forgotPassword' component={ForgotPassword}/>
        <UnauthenticatedRoute path='/resetPassword' component={ResetPassword}/>
        <Route path="/" render={() => <Redirect to="/landing" />} />
      </Switch>
    </IonReactRouter>
  </IonApp>
);

export default App;
