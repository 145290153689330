import React, { useEffect } from 'react';
import { Redirect, useLocation } from "react-router";
import { AUTH_VERIFIED } from '../features/auth/consts';
import { useSelector, useDispatch } from 'react-redux';

import qs from 'qs';
import { authSelector } from '../features/auth/selectors';
import { verify } from '../features/auth/actions';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonText,
  IonItem
} from "@ionic/react";
import ErrorMessage from '../components/ErrorMessage';

const Verify: React.FC = () => {
  const { authStatus, errorMessage } = useSelector(authSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const verificationCode = qs.parse(location.search, { ignoreQueryPrefix: true }).code;

  useEffect(() => { 
    if (verificationCode) {
      dispatch(verify(verificationCode as string));
    }
  }, [dispatch, verificationCode]);

  return (
    authStatus === AUTH_VERIFIED ? (<Redirect to='/login' />) :
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Verification
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ErrorMessage errorMessage={errorMessage} />
        <IonItem lines="none">
          <IonText class="ion-margin-top">A request has been sent to Highland Park Baptist Church to review your registration request. Look for an account verification message in your inbox in the next 24-48 hours. If approved, click on the link in that message to verify your account.</IonText>
        </IonItem>
      </IonContent>
    </IonPage>
  )
};

export default Verify;
